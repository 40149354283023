<template>
  <v-app id="inspire">
    <v-app-bar
    v-if="!$vuetify.breakpoint.smAndDown"
    app 
    class="white"
    absolute 
    flat
    src="@/assets/pict9.jpg"
    height="400"
    > 
    
    <v-row>
      <v-col style="margin-top: -6cm;">
        <v-img
          src="@/assets/logo.png" 
          contain
          alt="Logo" 
          :height="$vuetify.breakpoint.smAndDown ? '300' : '250'"
          :width="$vuetify.breakpoint.smAndDown ? '300' : '249'"/>
          <v-card 
            flat
            class="card1 ml-1"
            tile
            href="#laaste-kamp"
            width="240"
            style="margin-top: -2.1cm;">
            <v-card-text>
              <h2 class="white--text text-center" style="line-height: 27px; font-size: 18px;">Visit Laaste Kamp</h2>
            </v-card-text>
        </v-card>
        <v-card 
            flat
            class="card2 mt-1 ml-1"
            tile
            href="#historic-house"
            width="240"
            >
            <v-card-text>
              <h2 class="white--text text-center" style="line-height: 27px; font-size: 18px;">Visit Historic House</h2>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-app-bar>
  <v-app-bar
    v-if="$vuetify.breakpoint.smAndDown"
    app 
    class="white"
    absolute 
    flat
    src="@/assets/logo.png"
    :height="$vuetify.breakpoint.width < 500 ? '120' : '220'"
    
    > 
    
  </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app absolute class="white" style="display: block;">
      <v-row> 
        <v-col class="main--text mt-12" align="center">
          <h3 class="text-center">A ZEE Group Initiative</h3><br/>
          <a href="https://www.zeegroup.co.za/" target="_blank" style="text-decoration: none;"><v-img contain height="200" src="@/assets/Zeelogo.png"/></a>
      </v-col>
      </v-row>
      <v-row>
       <v-col class="test--text" align="center">
        Laaste Kamp ©{{ date.getFullYear()}}
       </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    date: new Date()
    //
  }),
  mounted(){
  }
};
</script>
<style scoped>
.card1, .card2{
  background-color: #191919;
}
#inspire{
  font-family: Verdana;
}
</style>
