import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCf0A5HXuAF-sIcnzRMKgXs_act16RLfu0",
  authDomain: "laaste-kamp.firebaseapp.com",
  projectId: "laaste-kamp",
  storageBucket: "laaste-kamp.appspot.com",
  messagingSenderId: "249399048575",
  appId: "1:249399048575:web:797e70af0a2b81c0c3024a",
  measurementId: "G-DE4KGS9DZJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
