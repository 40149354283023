import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            test: '#3E2723',
            primary: '#795548',
            secondary: '#F5F5F5',
            accent: '#E65100',
            error: '#FF9292',
            info: '#1B5E20',
            success: '#4CAF50',
            warning: '#FFC107',
          }
        }
      }
});
