<template>
<div style="overflow: hidden;">
  <v-row class="white--text" style="background: linear-gradient(180deg, black, #3E2723, black); margin-top: 6px;" no-gutters v-if="!$vuetify.breakpoint.smAndDown">
    <v-col>
      <v-container fluid :style="$vuetify.breakpoint.smAndDown ? 'display: block;' : ''" class="my-5 ml-4">
        <h1> How to Book </h1>
      <br/><br/>
      <v-row>
        <v-col align="justify" :style="$vuetify.breakpoint.width < 500 ? 'overflow: scroll;' : 'overflow: hidden;'">
          <h3>Mobile WhatsApp only </h3>
          <h3><a style="text-decoration: none;" class="red--text" href="https://api.whatsapp.com/send?phone=27788585927">078 858 5927</a> </h3>
          <h3><a style="text-decoration: none;" class="red--text" href="https://api.whatsapp.com/send?phone=27729001177">072 900 1177</a> </h3>
          <br/>
          <h3>Bookings </h3>
          <h3> <a style="text-decoration: none;" class="red--text" href="https://www.lekkeslaap.co.za/accommodation/laaste-kamp" target="_blank">Lekkeslaap Laaste Kamp</a></h3>
          <h3> <a style="text-decoration: none;" class="red--text" href="https://www.lekkeslaap.co.za/accommodation/baviaanskloof-bosvlakte-history-house" target="_blank">Lekkeslaap Historic House</a></h3>
          <a href=""></a>
          <br/>
          <h3 class="white--text"> Locations </h3>
          <h3> <a style="text-decoration: none;" class="red--text" href="https://maps.app.goo.gl/aqVDB83hjfrWryaEA?g_st=iw" target="_blank">Baviaanskloof Laaste Kamp</a> </h3> 
          <h3> <a style="text-decoration: none;" class="red--text" href="https://www.google.co.za/maps/@-33.591019,24.1771122,3a,75y,206.25h,81.03t/data=!3m6!1e1!3m4!1sZPnURz8kQt_gm87NuhS7oA!2e0!7i13312!8i6656?entry=ttu" target="_blank">Baviaanskloof Historic House</a> </h3> 
        </v-col>
      </v-row>
      </v-container>
    </v-col>
  </v-row>
  <v-row no-gutters class="mt-1" v-if="$vuetify.breakpoint.smAndDown">
    <v-col md="4" align="center">
      <v-img height="400" src="@/assets/pict9.jpg">
        <v-card 
          flat
          class="card1"
          tile
          href="#laaste-kamp"
          width="97%"
          >
          <v-card-text>
            <h2 class="white--text text-center" style="line-height: 27px;">Visit Laaste Kamp</h2>
          </v-card-text>
        </v-card>
        <v-card 
          flat
          class="card2 mt-1"
          tile
          href="#historic-house"
          width="97%"
          >
          <v-card-text>
            <h2 class="white--text text-center" style="line-height: 27px;">Visit Historic House</h2>
          </v-card-text>
        </v-card>
      </v-img>
    </v-col>
    </v-row>
    <v-row class="white--text" style="background: linear-gradient(180deg, black, #3E2723, black); margin-top: 6px;" no-gutters v-if="$vuetify.breakpoint.smAndDown">
      <v-col>
        <v-container fluid :style="$vuetify.breakpoint.smAndDown ? 'display: block;' : ''" class="my-12">
          <h1> How to Book </h1>
          <br/><br/>
          <v-row>
            <v-col align="justify" :style="$vuetify.breakpoint.width < 500 ? 'overflow: scroll;' : 'overflow: hidden;'">
              <h3>Mobile WhatsApp only </h3>
              <h3><a style="text-decoration: none;" class="red--text" href="https://api.whatsapp.com/send?phone=27788585927">078 858 5927</a> </h3>
              <h3><a style="text-decoration: none;" class="red--text" href="https://api.whatsapp.com/send?phone=27729001177">072 900 1177</a> </h3>
              <br/>
              <h3>Bookings </h3>
              <h3> <a style="text-decoration: none;" class="red--text" href="https://www.lekkeslaap.co.za/accommodation/laaste-kamp" target="_blank">Lekkeslaap Laaste Kamp</a></h3>
              <h3> <a style="text-decoration: none;" class="red--text" href="https://www.lekkeslaap.co.za/accommodation/baviaanskloof-bosvlakte-history-house" target="_blank">Lekkeslaap Historic House</a></h3>
              <a href=""></a>
              <br/>
              <h3 class="white--text"> Locations </h3>
              <h3> <a style="text-decoration: none;" class="red--text" href="https://maps.app.goo.gl/aqVDB83hjfrWryaEA?g_st=iw" target="_blank">Baviaanskloof Laaste Kamp</a> </h3> 
              <h3> <a style="text-decoration: none;" class="red--text" href="https://www.google.co.za/maps/@-33.591019,24.1771122,3a,75y,206.25h,81.03t/data=!3m6!1e1!3m4!1sZPnURz8kQt_gm87NuhS7oA!2e0!7i13312!8i6656?entry=ttu" target="_blank">Baviaanskloof Historic House</a> </h3> 
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row no-gutters style="margin-top: 4px;">
      <v-col class="pict-col" md="4" align="start">
        <v-img height="400" src="@/assets/pict4.jpg">
          <v-card 
            :class="$vuetify.breakpoint.smAndDown ? 'card2 mt-2 ml-1' : 'card2 mt-4 ml-6'"
            tile
            :width="$vuetify.breakpoint.smAndDown ? '70%' : '50%'"
            elevation="10"
            >
            <v-card-text>
              <h2 class="white--text text-center" style="line-height: 27px;">Laaste Kamp</h2>
            </v-card-text>
          </v-card>
        </v-img>
      </v-col>
      <v-col class="pict-col" md="4" align="center">
        <v-img height="400" src="@/assets/pict13.jpeg"/>
      </v-col>
      <v-col class="pict-col" md="4" align="center">
        <v-img height="400" src="@/assets/pict12.jpeg"/>
      </v-col>
  </v-row>
  <v-row no-gutters>
    <v-img class="pict-col" height="400" src="@/assets/pict1.jpg"/>
  </v-row>
  <v-row no-gutters>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/pict6.jpg"/>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/pict2.jpg"/>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/pict3.jpg"/>
    </v-col>
  </v-row>
  <v-row style="background: linear-gradient(to right, white, #FAFAFA); margin-top: 4px; margin-bottom: -4px;" no-gutters class="block" id="laaste-kamp">
    <v-col md="6">
      <v-container>
        <h1 class="test--text"> About Laaste Kamp </h1>
        <br/>
        <p style="text-align: justify;" class="mr-4"> Baviaanskloof Laaste Kamp offers unique and rustic bush campsites, 
          located 1 km from the Western gate to one of South Africa's World Heritage Sites, the Baviaanskloof Nature Reserve in the Eastern Cape. <br><br>

          We are the last camp before entering the Kloof from Uniondale or Willowmore. Approximately 97 km of scenic gravel roads. <br><br>

          Accommodation at Laaste Kamp consists of campsites, which are located in an environmentally sensitive, rough and isolated area of the 
          Baviaanskloof wilderness. The campsite is located at the last stop, situated on the border of the western fence and ideal to explore 
          the last 100km of the reserve. <br><br>

          The camp provides nature lovers the opportunity to explore a massive landscape with rugged mountainous regions, deep jagged gorges 
          and abundant wildlife. Guests have access to designated braai areas, a plunge pool, and a rock bonfire pit. The shaded areas have 
          green grass.<br><br>

          The Laaste Kamp campsite is situated 1 km from the Historic house.<br><br>

          Camp Guidelines
          <br><br>
          <ul v-for="(list, index) in list1" :key="index">
            <li class="py-1"> {{ list.item }} </li>
          </ul>
        </p>
      </v-container>
    </v-col>
    <v-col md="6" align="right" :class="$vuetify.breakpoint.smAndDown ? '' : 'col1'">
      <v-img v-if="$vuetify.breakpoint.smAndDown" src="@/assets/pict11.jpeg"/>
    </v-col>
  </v-row>
  <v-row style="background: linear-gradient(180deg, black, #3E2723, black); margin-bottom: 3px;" class="pa-10">
  </v-row>
  <v-row no-gutters>
    <v-col class="pict-col" md="4" align="start">
      <v-img height="400" src="@/assets/historic house/original_image (1).jpg">
        <v-card 
            :class="$vuetify.breakpoint.smAndDown ? 'card2 mt-2 ml-1' : 'card2 mt-4 ml-2'"
            tile
            :width="$vuetify.breakpoint.smAndDown ? '70%' : '50%'"
            elevation="10"
            >
            <v-card-text>
              <h2 class="white--text text-center" style="line-height: 27px;">Historic House</h2>
            </v-card-text>
          </v-card>
      </v-img>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/historic house/original_image (2).jpg"/>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/historic house/original_image (3).jpg"/>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-img class="pict-col" height="400" src="@/assets/historic house/original_image.jpg"/>
  </v-row>
  <v-row no-gutters>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/historic house/original_image (5).jpg"/>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/historic house/original_image (6).jpg"/>
    </v-col>
    <v-col class="pict-col" md="4" align="center">
      <v-img height="400" src="@/assets/historic house/original_image (7).jpg"/>
    </v-col>
  </v-row>
  <v-row style="background: linear-gradient(to right, white, #FAFAFA); margin-top: 4px;" no-gutters id="historic-house" class="block">
    <br/><br/>
    <v-col md="6">
      <v-container>
        <h1 class="test--text"> About Historic House </h1>
        <br/>
        <p style="text-align: justify;" class="mr-4"> Baviaanskloof Bosvlakte Historic House offers farm-style self-catering 
          accommodation and is located just off the R332 at the base of Baviaanskloof Nature Reserve. The farmhouse offers a 
          tranquil break from city life. <br><br>

          This historical holiday home can accommodate up to 6 persons and consists of 3 bedrooms and 2 bathrooms.<br><br>

          The kitchen has a fridge-freezer, an oven with a stove, a kettle, and a selection of utensils.<br><br>

          The open-plan living area offers a fireplace, an air conditioner, a ceiling fan, a dining table with seating, and couches 
          to relax on. The living area leads to an indoor braai area and out onto a veranda, which overlooks the outdoor braai area and the valley.<br><br>

          The property has ample open parking for 4x4 or overlanding vehicles, which are recommended to access the home.<br><br>

          The Baviaans Historic house is situated 1 km from Laaste Kamp.<br><br>

          <ul v-for="(list, index) in list2" :key="index">
            <li class="py-1">{{ list.item }}</li>
          </ul>
        </p>
      </v-container>
    </v-col>
    <v-col md="6" align="right" :class="$vuetify.breakpoint.smAndDown ? '' : 'col2'">
      <v-img v-if="$vuetify.breakpoint.smAndDown" src="@/assets/historic house/original_image (1).jpg"/>
    </v-col>
  </v-row>
  <v-row style="background: linear-gradient(180deg, black, #3E2723, black); margin-top: 7.7px;" no-gutters>
    <v-col>
      <Reviews/>
    </v-col>
  </v-row>
</div>
    
</template>

<script>
import Reviews from '@/components/Reviews.vue'
export default {
  name: 'Home',
  components: {
    Reviews
  },
  data(){
    return{
      date: '',
      list1: [
        {item: 'Flush toilets and basins. Bring own toilet paper.'},
        {item: 'Solar-powered showers - ideal to use in the afternoon when the sun shines.'},
        {item: 'No power, no reception (only MTN sometimes), and no Wi-Fi.'},
        {item: 'Be aware of wild animals. Do not leave the campsite at night - leopards, buffalo, and rhinos occasionally break through the fences.'},
        {item: 'Take your waste with you - no waste management facilities or recycling, baboons and monkeys live close by.'},
        {item: 'Free waste bags available from camp manager.'},
        {item: 'Bring own wood. Fires only allowed in the braai facilities - high unmanaged veld fire risk.'},
        {item: 'No loud music - animals live here in the reserve.'},
        {item: 'Use of facilities entirely at own risk.'}
      ],
      list2: [
        {item: 'Main and second bedroom fitted with 2 single beds.'},
        {item: 'Third bedroom furnished with a queen-size bed and small en-suite bathroom with a shower, basin and toilet.'},
        {item: 'Main bathroom has a shower and a bath, basin, and toilet.'},
        {item: 'Linen is provide.'},
        {item: 'Bring own towels.'},
        {item: 'No Wi-Fi. MTN works sporadically in the kloof.'},
        {item: 'Eskom power - accommodation is affected by loadshedding.'}
      ]
    }
  },
  methods: {
    check(){
      alert(this.date);
    }

  }

}
</script>

<style scoped>
p, a{
  font-size: 15px;
}
.pict-col{
  border: 4px solid white;
}
.col1{
  background-image: url('@/assets/pict11.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
.col2{
  background-image: url('@/assets/historic house/original_image (1).jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.card1, .card2{
  background-color: #191919;
}

</style>
